// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import MedicineCard from '../components/MedicineCard';
import medicinesData from '../data/medicines.json';
import '../styles/HomePage.css';

function HomePage() {
  const [medicines, setMedicines] = useState([]);

  useEffect(() => {
    setMedicines(medicinesData);
  }, []);

  return (
    <div className="homepage">
      <h2>Frequently bought medicines</h2>
      <div className="medicine-grid">
        {medicines.map((medicine) => (
          <MedicineCard key={medicine.id} {...medicine} />
        ))}
      </div>
    </div>
  );
}

export default HomePage;
