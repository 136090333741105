// src/components/MedicineCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/MedicineCard.css';

function MedicineCard({ id, name, dose, price, discountPrice, discountPercent }) {
  return (
    <div className="medicine-card">
      <h3>{name}</h3>
      <p>{dose}</p>
      <p>
        <span className="discount-price">₹{discountPrice}</span> 
        <span className="original-price">₹{price}</span> 
        <span className="discount-percent">{discountPercent}% off</span>
      </p>
      <Link to={`/product/${id}`} className="button-primary">
        View Details
      </Link>
    </div>
  );
}

export default MedicineCard;
