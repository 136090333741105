// src/components/SearchBar.js
import React, { useState } from 'react';
import medicinesData from '../data/medicines.json'; // Adjust the path based on your project structure
import '../styles/SearchBar.css';

function SearchBar({ onSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    if (term.length > 0) {
      const filteredSuggestions = medicinesData.filter(medicine =>
        medicine.name.toLowerCase().includes(term.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelect = (medicine) => {
    setSearchTerm(medicine.name);
    setShowSuggestions(false);
    if (onSelect) onSelect(medicine);
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        placeholder="Search for a medicine..."
        value={searchTerm}
        onChange={handleChange}
        className="search-input"
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((medicine) => (
            <li
              key={medicine.id}
              onClick={() => handleSelect(medicine)}
              className="suggestion-item"
            >
              {medicine.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchBar;
