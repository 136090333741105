// src/components/Header.js
import React from 'react';
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  const navigate = useNavigate();

  const handleSelectMedicine = (medicine) => {
    // Navigate to the selected medicine's page (assuming your route setup)
    navigate(`/product/${medicine.id}`);
  };

  return (
    <header className="header">
      <div className="logo">Vitarva 🩺 📦</div>
      <p>Medicines at your door-step under 15 minutes! ⏰</p>
      <SearchBar onSelect={handleSelectMedicine} />
    </header>
  );
}

export default Header;
