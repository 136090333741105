// src/pages/ProductPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import medicinesData from '../data/medicines.json';
import '../styles/ProductPage.css';

function ProductPage() {
  const { id } = useParams();
  const medicine = medicinesData.find((med) => med.id.toString() === id);
  const [deliveryTime, setDeliveryTime] = useState("");

  useEffect(() => {
    // Calculate the delivery time 15 minutes from now
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 15);
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    setDeliveryTime(`${hours}:${minutes} PM`);
  }, []);

  if (!medicine) return <p>Medicine not found.</p>;

  return (
    <div className="product-page">
      <img src={medicine.image} alt={medicine.name} />
      <h2>{medicine.name}</h2>
      <p>{medicine.dose}</p>
      <p className="price-container">
        <span className="original-price">₹{medicine.price}</span>
        <span className="discounted-price">₹{medicine.discountPrice}</span>
        <span className="discount-percent">{medicine.discountPercent}% off</span>
      </p>
      <button className="button-primary">Buy Now</button>
      <button className="button-primary">Add to Cart</button>
      <p>Get it delivered to your home by {deliveryTime} ⏰</p>
      <p>Cash on Delivery available ✅</p>
      <p>No convenience fee ✨</p>
      <p>No delivery fee 🚚</p>
      <p>Non refundable</p>
    </div>
  );
}

export default ProductPage;
